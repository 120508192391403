import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationComponent} from "./notification.component";
import {AngularSvgIconModule} from "angular-svg-icon";


@NgModule({
    declarations: [
        NotificationComponent,
    ],
    imports: [
        CommonModule,
        AngularSvgIconModule
    ], exports: [
        NotificationComponent
    ]
})
export class NotificationModule {
}
