<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>

<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">{{'gestao'|translate}}  | <span>{{'users'|translate}}</span><!----></h1>
            <div class="d-flex flex-column flex-sm-row col-sm-auto px-0" style="gap:15px" *ngIf=actionUserProfile()>
                <label class="btn btn-gray-fill mb-0">
                    <input type="file" id="doc-1" name="doc-1" required class="d-none" (change)="uploadExcel($event)"
                           accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                    <svg-icon class="right" [svgStyle]="{'transform':'rotate(180deg)'}"
                              src="assets/svg/downloads.svg"></svg-icon>
                    Importar Excel
                </label>
                <a href="javascript:void('');" class="btn btn-gray-fill" (click)="actionExcel()">
                    <svg-icon class="right"
                              src="assets/svg/downloads.svg"></svg-icon>
                    {{'exportarexcel'|translate}}
                </a>
                <a href="javascript:void('');" class="btn btn-secondary" (click)="actionNew()">
                    <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                    {{'add'|translate}}
                </a>
            </div>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" [(ngModel)]="filter.keyword" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'search' | translate}}</span>
                </label>
            </div>
            <div class="input-material" *ngIf=actionUserProfile()>
                <select id="profile" class="form-control" name="type" [(ngModel)]="filter.profile">
                    <option selected
                            [ngValue]="undefined">{{'select' | translate}}</option>
                    <option [ngValue]="'admin'">{{'managersName.admin'| translate}}</option>
                    <option [ngValue]="'manager'">{{'managersName.manager'| translate}}</option>
                    <option [ngValue]="'user'">{{'managersName.user'| translate}}</option>
                    <option [ngValue]="'leader'">{{'managersName.leader'| translate}}</option>
                </select>
                <label for="profile" class="mb-0 mr-3">{{'profile'|translate}}</label>
            </div>
            <div class="input-material flex-grow-1" *ngIf=actionUserProfile()>
                <select id="userByProfile" class="form-control" name="type" required
                        [(ngModel)]="filter.managerId" (ngModelChange)="getUsersLeaders()">
                    <option selected
                            [ngValue]="undefined">{{'select' | translate}}</option>
                    <option *ngFor="let u of usersByProfile" [ngValue]="u.id">{{u.name}}</option>
                </select>
                <label for="userByProfile" class="mb-0 mr-3">{{'managersName.manager'|translate}}</label>
            </div>
            <div class="input-material">
                <select id="userByLeader" class="form-control" name="type" required
                        [(ngModel)]="filter.leaderId">
                    <option selected
                            [ngValue]="undefined">{{'select' | translate}}</option>
                    <option *ngFor="let u of usersLeader" [ngValue]="u.id">{{u.name}}</option>
                </select>
                <label for="userByLeader" class="mb-0 mr-3">{{'managersName.leader'|translate}}</label>
            </div>
            <div class="input-material">
                <select id="unities" class="form-control" name="type" [(ngModel)]="filter.unityId">
                    <option selected
                            [ngValue]="undefined">{{'select' | translate}}</option>
                    <option *ngFor="let c of allUnities | async" [ngValue]="c.id">{{c.name}}</option>
                </select>
                <label for="unities" class="mb-0 mr-3">  {{'unidade'|translate}}</label>
            </div>
            <!--<div class="input-material select">
                <select id="country" class="form-control" name="type" [(ngModel)]="filter.country"
                        [disabled]="this.validateProfileCountry">
                    <option selected [ngValue]="undefined">{{'select' | translate}}</option>
                    <option *ngFor="let c of countries | async" [ngValue]="c.id">{{c.name}}</option>
                </select>
                <label for="country" class="mb-0 mr-3 disabled"> {{'country'|translate}}</label>
            </div>-->
            <a class="btn btn-primary btn-lg text-white"
               (click)="this.filter.page = 1; onFilter()">{{'buscar'|translate}}</a>
        </div>
        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">RE</th>
                    <th scope="col">{{'usuario'|translate}}</th>
                    <th scope="col">{{'unidade'|translate}}</th>
                    <th scope="col">{{'gestor'|translate}}</th>
                    <th scope="col">{{'leader'|translate}}</th>
                    <th scope="col">Centro Custo</th>
                    <th scope="col">{{'perfil'|translate}}</th>
                    <th scope="col">Status</th>
                    <!--<th scope="col" class="text-center">{{'country'|translate}}</th>-->
                    <th scope="col" class="w-10"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="users.results?.length === 0">
                    <td colspan="7">
                        <p class="text-center py-5">{{'nenhumresultado'|translate}}</p>
                    </td>
                </tr>
                <tr *ngFor="let x of users.results |
                paginate: {itemsPerPage: users.pageSize,
                currentPage: users.currentPage,
                totalItems: users.totalCount}">
                    <td>{{x.re}}</td>
                    <td>{{x.name}}</td>
                    <td>{{x.unity?.name}}</td>
                    <td>{{x.managerBy?.name ? x.managerBy?.name : '-'}}</td>
                    <td>{{x.leadBy?.name ? x.leadBy?.name : '-'}}</td>
                    <td>{{x.costCenter}}</td>
                    <td>{{getMangerTypeByKey(x.profile)}}</td>
                    <td>{{!x.status ? '' : getTranslate(x.status)}}</td>
                    <!--                <td>{{x.unity.name}}</td>-->
                    <td class="text-right">
                        <button (click)="actionEdit(x)">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </button>
                        <button (click)="actionDelete(x.id)" *ngIf=actionUserProfile()>
                            <svg-icon src="assets/svg/delete.svg"></svg-icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
        <!--<div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    &lt;!&ndash;                <th scope="col">ID</th>&ndash;&gt;
                    <th scope="col">RE</th>
                    <th scope="col">{{'usuario'|translate}}</th>
                    <th scope="col">{{'unidade'|translate}}</th>
                    <th scope="col">Gestor</th>
                    <th scope="col">Centro de Custo</th>
                    <th scope="col">{{'perfil'|translate}}</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="users.length === 0">
                    <td colspan="8">
                        <p class="text-center py-5">{{'nenhumresultado'|translate}}</p>
                    </td>
                </tr>
                <tr *ngFor="let x of users">
                    &lt;!&ndash;                <td>&ndash;&gt;
                    &lt;!&ndash;                    <div class="d-flex align-items-center" style="gap: 18px">&ndash;&gt;
                    &lt;!&ndash;                        0215&ndash;&gt;
                    &lt;!&ndash;                    </div>&ndash;&gt;
                    &lt;!&ndash;                </td>&ndash;&gt;
                    <td>{{x.re}}</td>
                    <td>{{x.name}}</td>
                    <td>{{x.unity.name}}</td>
                    <td>{{x.productManager}}</td>
                    <td>{{x.budget}}</td>
                    <td>{{getMangerTypeByKey(x.profile)}}</td>
                    <td>{{!x.status ? '' : getTranslate(x.status)}}</td>
                    &lt;!&ndash;      &lt;!&ndash;                <td>{{x.unity.name}}</td>&ndash;&gt;
                          <td class="d-flex flex-column align-items-center justify-content-center">
                              <img src="{{x.country?.flag}}" width="25">{{x.country?.name}}

                          </td>&ndash;&gt;
                    <td>
                        <button (click)="actionEdit(x)">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </button>
                        <button (click)="actionDelete(x.id)" *ngIf=actionUserProfile()>
                            <svg-icon src="assets/svg/delete.svg"></svg-icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>-->
    </div>
</div>


<div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="text-primary header-title">
                    {{'usuario'|translate}}
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalUser')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">
                        <div class="col-12 col-md-4 d-flex justify-content-center">
                            <div class="profile-box" (click)="showModal('modalCropLogin')">
                                <ng-container *ngIf="isNullOrUndefined(croppedImage)">
                                    <img class="profile-image-image" id="svg_login_profile"
                                         src="{{isNullOrUndefined(model.photo) ? 'assets/images/photologin.png' : model.photo}}"
                                         alt="photo"/>
                                </ng-container>
                                <img class="profile-box-image" *ngIf="croppedImage" src="{{croppedImage}}" alt="">
                            </div>
                        </div>

                        <div class="col-12 col-md-8">
                            <div class="row">
                                <div class="col-12">
                                    <div class="input-material">
                                        <input id="nameUser" class="form-control" type="text" [(ngModel)]="model.name"
                                               required maxlength="150"/>
                                        <label for="nameUser">{{'nomecompleto'|translate}}</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="input-material">
                                        <input id="reUser" class="form-control" type="text" [(ngModel)]="model.re"
                                               required maxlength="100"/>
                                        <label for="reUser">RE</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="input-material">
                                        <input id="costCenter" class="form-control" type="text"
                                               [(ngModel)]="model.costCenter"
                                               required maxlength="100"/>
                                        <label for="costCenter">{{'centrocusto'|translate}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <!--<input id="profileuser" class="form-control" type="text" [(ngModel)]="model.profile"
                                       required maxlength="15"/>-->
                                <select id="userprofile" class="form-control" name="type" required
                                        [(ngModel)]="model.profile"
                                        (ngModelChange)="setManagerLeader($event)"
                                        [disabled]="!actionUserProfile()">
                                    <option selected
                                            [ngValue]="undefined">{{'select' | translate}}</option>
                                    <!--<option [ngValue]="'admin'">Administrador</option>-->
                                    <option [ngValue]="'manager'">{{'gestor'|translate}}</option>
                                    <option [ngValue]="'user'">{{'usuario'|translate}}</option>
                                    <option [ngValue]="'admin'">{{'admin'|translate}}</option>
                                    <option [ngValue]="'leader'">{{'leader'|translate}}</option>
                                </select>
                                <label for="userprofile">{{'perfil'|translate}}</label>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input id="area" class="form-control" type="text" [(ngModel)]="model.area"
                                       required maxlength="100"/>
                                <label for="area">{{'area'|translate}}</label>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="input-material">
                                <input id="directorate" class="form-control" type="text" [(ngModel)]="model.directorate"
                                       required maxlength="100"/>
                                <label for="directorate">VP/Diretoria</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4" *ngIf="actionUserProfile()">
                            <div class="input-material">
                                <select id="manager" class="form-control" name="type" required
                                        [ngClass]="{'valid':isEnabledManager}"
                                        [(ngModel)]="userManegerBy"
                                        [disabled]="isEnabledManager"
                                        (ngModelChange)="getLeaders()">
                                    <option selected
                                            [ngValue]="undefined">{{'select' | translate}}</option>
                                    <option *ngFor="let c of usersManager" [ngValue]="c.id">{{c.name}}</option>
                                </select>
                                <!--<input id="gestor" class="form-control" type="text" [(ngModel)]="model.directorate"
                                       required maxlength="15"/>-->
                                <label for="manager">{{'gestorimediato'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="input-material">
                                <select id="leader" class="form-control" name="type" required
                                        [ngClass]="{'valid':isEnabledLeader}"
                                        [(ngModel)]="userLeadBy" [disabled]="isEnabledLeader">
                                    <option selected
                                            [ngValue]="undefined">{{'select' | translate}}</option>
                                    <option *ngFor="let c of setListLeaders()" [ngValue]="c.id">{{c.name}}</option>
                                </select>
                                <!--<input id="gestor" class="form-control" type="text" [(ngModel)]="model.directorate"
                                       required maxlength="15"/>-->
                                <label for="leader">{{'leader'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="input-material">
                                <input id="admissionDate" class="form-control" type="text"
                                       [(ngModel)]="model.admissionDate"
                                       [mask]="'d0/M0/0000'"
                                       [dropSpecialCharacters]="false"
                                       required maxlength="15"/>
                                <label for="admissionDate">{{'dataadmissao'|translate}}</label>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="input-material flex-grow-1">
                                <select id="countryUser" class="form-control" name="type" required
                                        [(ngModel)]="userCountry" (ngModelChange)="getUnitiesByCountry($event)">
                                    <option selected
                                            [ngValue]="undefined">{{'select' | translate}}</option>
                                    <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name}}</option>
                                </select>
                                <label for="countryUser" class="mb-0 mr-3">País</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="input-material flex-grow-1">
                                <select id="unityUser" class="form-control" name="type" required
                                        [(ngModel)]="userUnity">
                                    <option selected
                                            [ngValue]="undefined">{{'select' | translate}}</option>
                                    <option *ngFor="let u of unities" [ngValue]="u.id">{{u.name}}</option>
                                </select>
                                <label for="unityUser" class="mb-0 mr-3">{{'unidade'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="input-material">
                                <input id="phone" class="form-control" type="text" [(ngModel)]="model.phone"
                                       [mask]="'(00) 0000-0000||(00) 00000-0000'"
                                       [dropSpecialCharacters]="false"
                                       [clearIfNotMatch]="true"
                                       required maxlength="15"/>
                                <label for="phone">{{'telefonerecado'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="input-material">
                                <input id="role" class="form-control" type="text" [(ngModel)]="model.role"
                                       required maxlength="100"/>
                                <label for="role">{{'cargo'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input id="officeEmail" class="form-control" type="text" [(ngModel)]="model.officeEmail"
                                       required maxlength="150"/>
                                <label for="officeEmail">E-mail Eurofarma</label>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input id="email" class="form-control" type="text" [(ngModel)]="model.email"
                                       required maxlength="150"/>
                                <label for="email">E-mail</label>
                            </div>
                        </div>


                        <div class="col-12 col-md-6 d-flex align-items-center justify-content-between">
                            <p class="switch_title">Status - {{model.status === 'active' ? 'Ativo' : 'Inativo'}}</p>
                            <label class="switch mb-0">
                                <input type="checkbox" [ngModel]="model.status === 'active'"
                                       (ngModelChange)="changeStatus($event)"
                                       name="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </div>

                        <div class="col-12 my-3">
                            <button class="btn btn-primary btn-lg text-white w-100" (click)="actionSave()">
                                <span class="text-white m-0">{{'confirmar'|translate}}</span></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<app-crop-image [cropId]="'modalCropLogin'"
                [cropAspectRatio]="4/4"
                [cropResizeToWidth]="576"
                [cropResizeToHeight]="576"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>
