import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnChanges, SimpleChanges
} from '@angular/core';
import {DatePicked} from "./model/date-picked";
import moment from "moment";
import {Router} from "@angular/router";
import {BaseComponent} from "../../base/base.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker-custom.component.html',
    styleUrls: ['./date-picker-custom.component.scss']
})
export class DatePickerCustomComponent extends BaseComponent implements OnChanges {
    @Input()
    start!: Date;

    @Input()
    end!: Date;

    @Input()
    minDate!: Date;

    @Input()
    isSingle: boolean = false;

    @Input()
    label: string = 'Período';

    @Input()
    directionDrops: string = 'down';

    @Input()
    disabled: boolean = false;

    @Output()
    selectDate: EventEmitter<DatePicked> = new EventEmitter<DatePicked>();

    options: any = {
        locale: super.objLocaleDateRangePicker(),
        alwaysShowCalendars: false,
        opens: 'left',
        drops: this.directionDrops,
        timeZone: 'LT'
    };

    constructor(public router: Router,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.options = this.getOptions();
    }

    getOptions(): any {
        if (this.isSingle) {
            return {
                singleDatePicker: this.isSingle,
                startDate: moment.utc(this.start),
                minDate: !this.isNullOrUndefined(this.minDate) ? moment.utc(this.minDate) : null,
                locale: super.objLocaleDateRangePicker(),
                alwaysShowCalendars: false,
                opens: 'left',
                drops: this.directionDrops,
            }
        } else {
            return {
                startDate: moment.utc(this.start),
                endDate: moment.utc(this.end),
                locale: super.objLocaleDateRangePicker(),
                alwaysShowCalendars: false,
                opens: 'left',
                drops: this.directionDrops,
                timeZone: 'LT'
            }
        }
    }

    selectRange(value: any) {
        this.selectDate.emit(new DatePicked(moment(value.start).toDate(), moment(value.end).toDate()));
    }

}
