import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePickerCustomComponent} from './date-picker-custom.component';
import {Daterangepicker} from "ng2-daterangepicker";
import {AngularSvgIconModule} from "angular-svg-icon";
import {NgxMaskModule} from "ngx-mask";


@NgModule({
    declarations: [
        DatePickerCustomComponent
    ],
    imports: [
        CommonModule,
        Daterangepicker,
        AngularSvgIconModule.forRoot(),
        NgxMaskModule.forRoot(),
    ],
    exports: [
        CommonModule,
        DatePickerCustomComponent
    ],
})
export class DatePickerCustomModule {
}
