
<div>
  <p class="text-white text-center">Autenticando ...</p>

  <p class="text-white text-center" style="font-size: 14px" *ngIf="token">TOKEN:<br/><br/> {{token}}</p>

  <p class="text-white text-center" style="font-size: 14px" *ngIf="error">Error: <br/><br/> {{error}}</p>
</div>


<div class="modal-custom modal fade" id="modalLoginEmail" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-header-title">Login</p>
        <a (click)="closeLoginMock()" class="btn" type="button">
          <svg-icon src="assets/svg/close.svg"></svg-icon>
        </a>
      </div>
      <div class="modal-body modal-body--small">
        <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
          <div class="input-material" style="flex-grow:3">
            <input id="start" type="text" class="form-control" [(ngModel)]="modelEmailMock"/>
            <!--<textarea id="start" class="form-control" rows="4" [(ngModel)]="budgetUpdateStatus.response"></textarea>-->
            <label for="start">{{'email'|translate}}</label>
          </div>
        </div>
        <button class="btn btn-block btn-warning mt-3" (click)="onLoginMock()">{{'entrar'|translate}}</button>
      </div>
    </div>
  </div>
</div>
