<div class="single__page">
  <h1 class="mb-5">
    <svg-icon src="assets/svg/logo-eurofarma.svg"></svg-icon>
  </h1>
  <p>
    {{'vocelogado'|translate}}
  </p>
  <p>
    <a href="javascript:void('Login');" [routerLink]="['/authenticate']">Clique aqui para <strong>ENTRAR!</strong></a>
  </p>
</div>
