<div class="modal-custom modal fade" id="modalFeedback" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-header-title">Feedback</p>
                <a (click)="closeModal('modalFeedback')" class="btn" type="button">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row">
                    <div class="col-12">
                        <div *ngIf="isNullOrUndefined(user)" class="input-material">
                            <ng-multiselect-dropdown
                                    id="selectedUser"
                                    (onFilterChange)="onKey($event)"
                                    [placeholder]="'selecione'"
                                    [settings]="dropdownSettingsUnities"
                                    [data]="userByName"
                                    [(ngModel)]="selectedUser"
                            >
                            </ng-multiselect-dropdown>
                            <label for="selectedUser" class="disabled">Colaborador</label>
                        </div>
                        <div class="row mb-3 align-items-center" *ngIf="!isNullOrUndefined(user)">
                            <div class="col-auto">
                                <img class="feedback_img" alt="photo"
                                     src="{{isNullOrUndefined(user.photo)? 'assets/images/user-lg.png': user.photo}}">
                            </div>
                            <dl class="col">
                                <dt class="feedback_lbl mb-1">|{{user.re}}| {{user.name}}</dt>
                                <dd class="feedback_value">{{'managersName.'+ user.profile|translate}}</dd>
                            </dl>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <app-date-picker [isSingle]="true" [label]="'Data'"
                                         [start]="feedback.date"
                                         (selectDate)="selectedDate($event)"></app-date-picker>
                    </div>
                    <div class="col-12">
                        <div class="input-material">
                              <textarea id="desc" [(ngModel)]="feedback.description" required maxlength="1000"
                                        class="form-control"></textarea>
                            <label for="desc">{{'descrevaaqui'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <p class="sub">{{'camposobrigatorios'|translate}}</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-6">
                        <a class="btn btn-outline-primary btn-lg w-100"
                           (click)="closeModal('modalFeedback')">Cancelar</a>
                    </div>
                    <div class="col-6">
                        <a (click)="onSave()" class="btn btn-primary btn-lg text-white w-100">{{'salvar'|translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
