import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {HomeComponent} from './views/home/home.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {AuthGuard} from './service/auth-guard.service';
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {AccessControlComponent} from './views/access-control/access-control.component';
import {AccessControlHomeComponent} from './views/access-control/home/access-control-home.component';
import {CampaignComponent} from "./views/campaign/campaign.component";
import {CampaignHomeComponent} from "./views/campaign/home/campaign-home.component";
import {CampaignDetailComponent} from "./views/campaign/detail/campaign-detail.component";
import {ConfigComponent} from "./views/config/config.component";
import {ConfigHomeComponent} from "./views/config/config-home/config-home.component";

const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'authenticate', component: AuthenticateComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {
        path: '', component: MainComponent,
        children: [
            {path: 'dashboard', component: DashboardComponent},
            {path: 'user', component: UserComponent},
            {
                path: 'access-control', component: AccessControlComponent, children: [
                    {path: '', component: AccessControlHomeComponent},
                ]
            },
            {
                path: 'avaliation',
                loadChildren: () => import('./views/avaliation/avaliation.module').then(m => m.AvaliationModule),
                data: {animationState: 'rightToLeft'}
            },
            {
                path: 'campaign', component: CampaignComponent, children: [
                    {path: '', component: CampaignHomeComponent},
                    {path: 'detail/:id', component: CampaignDetailComponent},
                    {path: 'create', component: CampaignDetailComponent},
                ]
            },
            {
                path: 'config', component: ConfigComponent, children: [
                    {path: '', component: ConfigHomeComponent},
                ]
            },
            {path: 'home', component: HomeComponent},
            {path: '', component: HomeComponent},
        ],
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
