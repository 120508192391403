import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeedbackComponent} from "./feedback.component";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {FormsModule} from "@angular/forms";
import {DatePickerCustomModule} from "../date-picker-custom/date-picker-custom.module";
import {AngularSvgIconModule} from "angular-svg-icon";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        FeedbackComponent,
    ],
    exports: [
        FeedbackComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        DatePickerCustomModule,
        NgMultiSelectDropDownModule,
        AngularSvgIconModule.forRoot(),

    ]
})
export class FeedbackModule {
}
