<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">{{'gestao'| translate}} | <span>{{'configuraravaliacao'|translate}}</span><!----></h1>
            <a href="javascript:void('');" class="btn btn-secondary" [routerLink]="['/campaign/create']">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                {{'novacampanha'|translate}}
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'buscar'|translate}}</span>
                </label>
            </div>
            <a class="btn btn-primary btn-lg text-white offset-md-2"
               (click)="this.filter.page = 1; onFilter()">{{'buscar'|translate}}</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">{{'campanha'|translate}}</th>
                    <th scope="col">{{'criadopor'|translate}}</th>
                    <th scope="col">{{'date'|translate}}</th>
                    <th scope="col" class="text-center">{{'iniciofim'|translate}}</th>
                    <th scope="col" class="text-center">{{'qtdcolaboradores'|translate}}</th>
                    <th scope="col" class="text-center"></th>
                </tr>
                </thead>
                <tbody>

                <tr *ngFor="let x of evaluations.results |
                paginate: {itemsPerPage: evaluations.pageSize,
                currentPage: evaluations.currentPage,
                totalItems: evaluations.totalCount}">
                    <td>{{x.name}}</td>
                    <td>{{x.createdBy?.name}}</td>
                    <td class="text-center">{{x.createdAt | date:'dd/MM/yyyy'}}</td>
                    <td class="text-center">{{x.start | date:'dd/MM/yyyy':'UTC-0'}}
                        - {{x.end| date:'dd/MM/yyyy':'UTC-0'}}
                        <svg-icon *ngIf="x.status !== 'finished'" (click)="showModalDate(x.id)" src="assets/svg/calendar.svg"></svg-icon></td>
                    <td class="text-center">{{x.usersCount}}</td>
                    <td class="text-center">
                        <ng-container *ngIf="x.status == 'finished'; else elseTemplate">
                            <a href="javascript:void(0)" [routerLink]="'/campaign/detail/'+ x.id">
                                <svg-icon src="assets/svg/eye.svg"></svg-icon>
                            </a>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div class="row mx-n1 justify-content-center">
                                <div class="col-auto px-1">
                                    <a href="javascript:void(0)" [routerLink]="'/campaign/detail/'+ x.id">
                                        <svg-icon *ngIf="!isCampaign(x)" src="assets/svg/edit.svg"></svg-icon>
                                        <svg-icon *ngIf="isCampaign(x)" src="assets/svg/eyes.svg"></svg-icon>
                                    </a>
                                </div>
                                <div class="col-auto px-1">
                                    <a href="javascript:void(0)" (click)="actionDelete(x.id)">
                                        <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </ng-template>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

    </div>
</div>






<div class="modal-custom modal fade" id="modalData" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-header-title">Alterar Data</p>
                <a (click)="closeModal('modalData')" class="btn" type="button">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row">
                    <div class="col-12">
                        <p class="sub">{{'camposobrigatorios'|translate}}</p>
                    </div>

                    <app-date-picker [start]="evaluation.start" [isSingle]="true"
                                     [label]="'Data Final'"
                                     [end]="evaluation.end" [minDate]="minDate"
                                     (selectDate)="selectedDate($event)" class="w-100">
                    </app-date-picker>
                </div>
                <div class="row">
                    <div class="col-6">
                        <a class="btn btn-outline-primary btn-lg w-100"
                           (click)="closeModal('modalData')">{{'cancel'|translate}}</a>
                    </div>
                    <div class="col-6">
                        <a
                           class="btn btn-primary btn-lg text-white w-100"
                           (click)="action()">{{'save'|translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

