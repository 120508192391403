import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../../generated/graphql';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {
    isAuthenticated = false;

    private tokenDataSource = new BehaviorSubject<string>(null);
    token = this.tokenDataSource.asObservable();

    private userDataSource = new BehaviorSubject<User>(new User());
    user = this.userDataSource.asObservable();

    private notificationDataSource = new BehaviorSubject<Notification[]>([]);
    notification = this.notificationDataSource.asObservable();

    constructor(http: HttpClient) {
        super(http);
    }

    updateToken(token: string) {
        localStorage.setItem('token', token);
    }

    getAuthenticate(model) {
        const url = `${this.BASE_URL}/user/authenticate`;
        return this.http.post(url, model);
    }

    getStatusAuthenticated() {
        const token = localStorage.getItem('token') as string;
        if (token != null) {
            this.isAuthenticated = true;
        } else {
            this.isAuthenticated = false;
        }
        return this.isAuthenticated;
    }

    search(page, filter) {
        const url = `${this.BASE_URL}/user/search/${page}`;
        return this.http.post(url, filter);
    }

    getMe() {
        const url = `${this.BASE_URL}/user/me`;
        return this.http.get(url).subscribe({
            next: data => {
                this.userDataSource.next(data as User);
                this.getUserLocal();
            }
        });
    }

    create(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.put(url, model);
    }

    updateUser(user: User, tokenChanged: boolean = false, t: string = null) {
        if (tokenChanged) {
            this.updateToken(t);
            this.tokenDataSource.next(t);
        }
        this.userDataSource.next(user);
    }

    getUserLocal(): User {
        return this.userDataSource.value;
    }

    delete(id) {
        const url = `${this.BASE_URL}/user/${id}`;
        return this.http.delete(url);
    }

    getNotification() {
        const url = `${this.BASE_URL}/user/notification`;
        this.http.get(url).subscribe({
            next: data => {
                this.notificationDataSource.next(data as Notification[]);
            }, error: err => {
            }
        });
    }

    updateNotification(id) {
        const url = `${this.BASE_URL}/user/notification/${id}`;
        return this.http.put(url, {});
    }

    upload(file, id) {
        const url = `${this.BASE_URL}/users/file/${id}`;
        return new Observable((observer) => {
            //    observer.next();
            //  observer.complete();
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }

}
