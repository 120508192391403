import {Component, OnInit, OnDestroy, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {DatePicked} from "../date-picker-custom/model/date-picked";
import {
    CreateFeedbackGQL,
    CreateFeedbackInput,
    Feedback, UpdateFeedbackGQL, UpdateFeedbackInput, User,
    UserByNameGQL, UserByNameQuery
} from "../../../generated/graphql";
import moment from "moment";
import {QueryRef} from "apollo-angular";

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
    solicitationQuery: QueryRef<UserByNameQuery>;

    userByName: User[] = [];
    dropdownSettingsUnities = {};
    selectedUser: any;
    userId: string = '';

    @Input()
    user: User;

    @Input()
    feedback: Feedback = new Feedback();

    @Output()
    updateList: EventEmitter<any> = new EventEmitter<any>();

    constructor(public router: Router,
                private userByNameGQL: UserByNameGQL,
                private createFeedbackGQL: CreateFeedbackGQL,
                private updateFeedbackGQL: UpdateFeedbackGQL,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
        if (!this.isNullOrUndefined(this.user)) {
            this.selectedUser = [{id: this.user.id, name: this.user.name}];
        } else this.selectedUser = null;
        if (this.isNullOrUndefined(this.feedback.date)) {
            this.feedback.date = moment().utc(true).format();
        }
    }


    ngOnInit(): void {
        this.dropdownSettingsUnities = {
            singleSelection: true,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true,
            searchPlaceholderText: 'Buscar',
            noDataAvailablePlaceholderText: this.translate.instant('NoContributorsFound'),
            allowRemoteDataSearch: true
        };
        if (this.isNullOrUndefined(this.user)) {
            this.actionUser();
        }
    }

    ngOnDestroy(): void {
        super.destroyModal('modalFeedback');
    }

    actionUser() {
        this.solicitationQuery = this.userByNameGQL.watch({data: this.userId});
        this.solicitationQuery.valueChanges.subscribe(({data}) => {
            this.userByName = data.userByName as User[];
        });
    }

    onKey(value: any) {
        this.solicitationQuery.refetch({
            data: value.length < 3 ? '' : value
        });
    }

    selectedDate(event: DatePicked) {
        this.feedback.date = moment(event.end).utc(true).format();
    }

    onSave() {
        const arrValidateFields = [
            {value: this.selectedUser, text: 'Colaborador<br>'},
            {value: this.feedback.description, text: 'Descrição<br>'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warning'), `${this.translate.instant('FillFieldsCorrectly')} <br> ${stringError}`);
            return;
        }

        if (super.isNullOrUndefined(this.feedback.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        let data: CreateFeedbackInput = {
            date: this.feedback.date,
            description: this.feedback.description,
            user: {id: this.selectedUser[0].id}
        };
        this.createFeedbackGQL.mutate({input: data}).subscribe(() => {
            this.closeModal('modalFeedback')
            this.updateList.emit();
            this.showMessage(this.translate.instant('baseComponent.success'), 'Feedback realizado com  sucesso', 'success');
        }, error => super.onError(error));
    }

    actionUpdate() {
        let data: UpdateFeedbackInput = {
            id: this.feedback.id,
            date: this.feedback.date,
            description: this.feedback.description,
            user: {id: this.selectedUser[0].id}
        };
        this.updateFeedbackGQL.mutate({input: data}).subscribe(() => {
            this.closeModal('modalFeedback')
            this.updateList.emit();
            this.showMessage(this.translate.instant('baseComponent.success'), 'Feedback atualizado com  sucesso', 'success');
        }, error => super.onError(error));
    }
}
